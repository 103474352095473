<template>
  <div
    class="thank-you-container"
    :class="showThankYou ? 'fade-in' : 'fade-out'"
  >
    <p class="thank-you">Thank you for your feedback.</p>
  </div>
</template>
<script>
export default {
  name: 'ClientFeedbackThankYou',
  props: {
    showThankYou: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped lang="scss">
// Palette
$black1: #231F20;
$black1-alpha: #231F2040;

// Font Weights
$weight600: 600;

// Typography: Mixins
@mixin typography($font-size: 1.0em, $font-weight: $weight400, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.2;
  color: $color;
}

@mixin font-small($font-weight: $weight400) {
  @include typography(
    $font-size: 0.875em,
    $font-weight: $font-weight
  );
}

// Component
.thank-you-container {
  text-align: center;
  background: white;
  color: inherit;
  border: 1px solid #00000020;
  border-radius: 0.375em;
  box-shadow: 0   1px 4px $black1-alpha,
  1px 1px 0   $black1-alpha,
  0   0   0   $black1-alpha,
  1px 0   0   $black1-alpha;
  opacity: 0;
  transition: opacity 2s ease;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }

  .thank-you {
    @include font-small(
      $font-weight: $weight600
    );
    margin: 0;
    padding: 1.0em 1.5em;
  }
}
</style>
