var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "thank-you-container",
      class: _vm.showThankYou ? "fade-in" : "fade-out",
    },
    [
      _c("p", { staticClass: "thank-you" }, [
        _vm._v("Thank you for your feedback."),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }